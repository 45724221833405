import * as React from "react";
import "./Link.scss";

// markup
export default function Link(props) {
	return (
		<a
			href={props.url}
			target={props.target}
			className={
				"link " +
				(props.type ? "link--" + props.type + " " : " ") +
				(props.className ? props.className + " " : "")
			}
		>
			{props.children}
		</a>
	);
}
