import * as React from "react";
import "./Form.scss";

// Props
// Label
// Type

// markup
export default function FormRow(props) {
	return (
		<div
			className={
				"form__row " +
				(props.className ? props.className : "")
			}
		>
			{props.children}
		</div>
	);
}
