import * as React from "react";
import "./Form.scss";

// Props
// Label
// Type

// markup
export default function FormFeedback(props) {
	return (
		<fieldset
			className={
				"form__fieldset " +
				(props.type ? "form--" + props.type + " " : "") +
				(props.className ? props.className : "")
			}
		>
			<label className="form__label">{props.label}</label>
			{props.children}
		</fieldset>
	);
}
