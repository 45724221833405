import * as React from "react";
import "./Form.scss";

export default function Form(props) {
	return (
		<div
			className={
				"form " +
				(props.type ? "form--" + props.type + " " : "") + 
				(props.className ? props.className  + " " : "")
			}
		>
			<form 
				className="form__function" 
				method="POST" 
				name={props.name} 
				id={props.id}
				onSubmit={props.submit}>
					<div className="form__container">
						{props.children}
					</div>
			</form>
		</div>
	);
}
