import * as React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp"
import Form from "../Form/Form";
import FormField from "../Form/FormField";
import FormRow from "../Form/FormRow";
import "./Newsletter.scss";

export default class Newsletter extends React.Component {
	state = {
    email: '',
    message: '',
    display: 'none',
    messageState: 'fart',
  }

  handleChange = e => {
    this.setState({ email: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const result = await addToMailchimp(this.state.email)
    this.setState({ message: result.msg })
    this.setState({ display: 'block' })
    this.setState({ messageState: result.result })
	};

render() {
    return (
			<Form 
				submit={this.handleSubmit} 
				className={"newsletter " + this.props.className}
				type={this.props.type}
				name="subscribeForm" 
				id="subscribe-form" >
				<FormRow>
	        <FormField>
		        <input
		          type="email"
		          name="email"
		          className="form__input newsletter__input u-mar-right-sm"
		          // autoComplete="email"
		          placeholder="Enter Email Address..."
		          value={this.state.email}
		          onChange={this.handleChange}
		        />
	        </FormField>
	        <button className="button" type="submit">
	            Subscribe
	        </button>
        </FormRow>
	        <p style={{ display: this.state.display }} className={ 'form__feedback form--absolute u-mar-top-sm ' + (this.state.messageState === 'error' ? ('form--negative') : ('form--positive'))}>{this.state.message}</p>
      </Form>
    )
  }
}